import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from 'components/containers/Grid';
import { Form, Field } from 'react-final-form';
import isEmail from 'validator/es/lib/isEmail';
import Inputs from './Inputs';
import Button from '../Button';
import Textarea from '../Textarea';
import Checkbox from '../Checkbox';
import ImageUpload from '../ImageUpload';

const StyledForm = styled.form`
  margin: 40px auto;
  ${({ theme }) => theme.mq.s} {
    margin: 20px auto;
  }
`;

const StyledFeedback = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme, indent }) => (indent === 'success' ? theme.color.text : 'firebrick')};
  text-align: center;
`;

const ContactForm = () => {
  const [sending, setSending] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [images, setImages] = useState({
    front: { preview: null, file: null, error: null },
    side: { preview: null, file: null, error: null },
    face: { preview: null, file: null, error: null },
  });

  const updateImage = ({ preview, file, name }) => {
    setImages(prevImages => ({
      ...prevImages,
      [name]: { preview, file, error: null },
    }));
  };

  const setImageError = (name, error) => {
    setImages(prevImages => ({
      ...prevImages,
      [name]: { ...prevImages[name], error },
    }));
  };

  const removeImage = (name) => {
    setImages(prevImages => ({
      ...prevImages,
      [name]: { preview: null, file: null, error: null },
    }));
  };

  const validate = values => {
    const errors = {};
    if (!values.name) { errors.name = 'Pole wymagane / required'; }
    if (!values.surname) { errors.surname = 'Pole wymagane / required'; }
    if (!values.bust) { errors.bust = 'Pole wymagane / required'; }
    if (!values.waist) { errors.waist = 'Pole wymagane / required'; }
    if (!values.hips) { errors.hips = 'Pole wymagane / required'; }
    if (!values.height) { errors.height = 'Pole wymagane / required'; }
    if (!values.shoe) { errors.shoe = 'Pole wymagane / required'; }
    if (!values.city) { errors.city = 'Pole wymagane / required'; }
    if (!values.bthdate) { errors.bthdate = 'Pole wymagane / required'; }
    if (!values.email) {
      errors.email = 'Pole wymagane / required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Podaj poprawny email / provide email address';
    }
    if (!values.experience) { errors.experience = 'Pole wymagane / required'; }
    if (!values.instagram) { errors.instagram = 'Pole wymagane / required'; }
    if (!values.phone) { errors.phone = 'Pole wymagane / required'; }
    if (!values.term1) { errors.term1 = 'Wymagana zgoda / required'; }
    if (!values.term2) { errors.term2 = 'Wymagana zgoda / required'; }
    setDisabled(false);
    return errors;
  };

  const onSubmit = async values => {
    setSending(true);
    setDisabled(true);
    setFeedback(false);
    let invalid = false;
    const form = new FormData();
    Object.entries(values).forEach(([k, v]) => form.append(k, v));
    Object.entries(images).forEach(([k, v]) => {
      const { file } = v;
      if (file) form.append(k, file);
      else {
        setImageError(k, 'Zdjęcie jest wymagane');
        invalid = true;
      }
    });
    if (invalid) return;
    const { status } = await fetch(`${process.env.GATSBY_SERVICE_URL}/contact`, {
      method: 'POST',
      body: form,
    });
    setSending(false);
    if (status === 200) {
      setFeedback({ indent: 'success', msg: 'Zgłoszenie zostało przesłane' });
    } else {
      setFeedback({
        indent: 'error',
        msg: 'Wystąpił problem z wysłaniem wiadmomości, spróbuj ponownie lub napisz na contact@onmove.pl',
      });
      setDisabled(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Grid justify="stretch">
            <Grid cols="auto" colsS="auto">
              <Inputs />
            </Grid>
            <Field name="experience">
              { props => <Textarea label="doświadczenie / experience" {...props} /> }
            </Field>
            <Grid cols="1fr 1fr 1fr" gap="14px" justify="stretch">
              <ImageUpload
                name="front"
                label="Cała sylwetka przód"
                updateImage={updateImage}
                removeImage={removeImage}
                setError={setImageError}
                preview={images.front.preview}
                error={images.front.error}
                imageUrl="/front.jpg"
              />
              <ImageUpload
                name="side"
                label="Cała sylwetka bokiem"
                updateImage={updateImage}
                removeImage={removeImage}
                setError={setImageError}
                preview={images.side.preview}
                error={images.side.error}
                imageUrl="/side.jpg"
              />
              <ImageUpload
                name="face"
                label="Zbliżenie twarzy"
                updateImage={updateImage}
                removeImage={removeImage}
                setError={setImageError}
                preview={images.face.preview}
                error={images.face.error}
                imageUrl="/face.jpg"
              />
            </Grid>
            <Field name="term1" type="checkbox">
              {props => (
                <Checkbox {...props}>
                  Niniejszym wyrażam zgodę na przetwarzanie moich ww. danych osobowych, w celu kontaktu dotyczącego współpracy zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (tj. Dz.U. z 2017 r. poz. 1219) oraz regulaminem.
                </Checkbox>
              )}
            </Field>
            <Field name="term2" type="checkbox">
              {props => (
                <Checkbox {...props}>
                  Niniejszym akceptuję treść poniższej klauzuli informacyjnej oraz regulamin polityki prywatności. Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r. (dalej: RODO) informuję, iż: 1. administratorem Pani/Pana danych osobowych jest „ONMOVE" SARA CZARNA z siedzibą, adres: ul.3 Marca 26, 78-300 Świdwin.
                </Checkbox>
              )}
            </Field>
            <Button
              disabled={disabled || sending}
              loading={sending}
              component="button"
              type="submit"
            >
              WYŚLIJ
            </Button>
            <StyledFeedback indent={feedback && feedback.indent}>
              {feedback && feedback.msg}
            </StyledFeedback>
          </Grid>
        </StyledForm>
      )}
    />
  );
};

export default ContactForm;
