import React from 'react';
import Layout from 'components/layout/Layout';
import Container from 'components/containers/Container';
import ContactForm from 'components/ContactForm/ContactForm';
import Grid from "../components/containers/Grid";
import ContactRules from "../components/ContactRules";
import SEO from "../components/Seo";

const becomeModel = ({location}) => (
  <Layout location={location}>
    <SEO title="BECOME A MODEL" />
    <Container size="m">
      <Grid gap={'2rem'} cols="50% 50%" colsM="auto" colsS="auto" align="baseline">
        <ContactForm />
        <ContactRules />
      </Grid>
    </Container>
  </Layout>
);

export default becomeModel;
