import React from 'react';
import styled from "styled-components";

const StyledWrapper = styled.div`
  ${({theme}) => theme.mq.m} {
    grid-row: 1;
  }
`;

const StyledContentWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  font-size: 1.6rem;
  width: 390px;
  margin: 0 auto;

  p {
    margin-bottom: .8rem;
    font-weight: 500;
  }

  ul {
    list-style: inside;

    li {
      font-size: 1.45rem;
      margin-bottom: .4rem;
    }
  }

  strong {
    display: block;
    padding-top: 1rem;
    text-decoration: underline;
    font-weight: 500;
  }

  ${({theme}) => theme.mq.m} {
    box-sizing: border-box;
    width: 100%;
    max-width: 480px;
    padding: 0 .5rem;
  }
`;

const StyledTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4rem;
  letter-spacing: 2px;

  ${({theme}) => theme.mq.m} {
    font-size: 2.25rem;
    margin: 2rem 0 3rem;
  }

  ${({theme}) => theme.mq.xs} {
    font-size: 1.8rem;
  }
`;

const StyledSubtitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;

  ${({theme}) => theme.mq.m} {
    font-size: 1.6rem;
  }
`;

const ContactRules = () => (
  <StyledWrapper>
    <StyledTitle>SUBMISSION | ZGŁOSZENIE</StyledTitle>
    <StyledContentWrapper>
      <StyledSubtitle>Podjęłaś decyzję i chcesz spróbować swoich sił w modelingu?<br/> Dobrze trafiłaś! Czas działać!</StyledSubtitle>
Kto może wysłać zgłoszenie ? Każdy! Pamiętaj jednak, że przeglądamy tylko dobrze wypełnione formularze więc zapoznaj się z poniższą instrukcją.
      <StyledSubtitle>Aby zgłosić się do agencji należy:</StyledSubtitle>
      <div>
        <p>1. Wykonać zdjęcia:</p>
        <ul>
          <li>w dziennym świetle tak aby dobrze była widoczna twarz i sylwetka</li>
          <li>zbliżenie buzi, cała sylwetka przodem i bokiem</li>
        </ul>
      </div>
      <div>
        <p>2. Ubranie:</p>
        <ul>
          <li>czarne legginsy/jeansy</li>
          <li>koszulka podkreślająca figurę</li>
          <li>szpilki</li>
        </ul>
      </div>
      <div>
        <p>3. Makijaż:</p>
        <ul>
          <li>buzia bez makijażu</li>
        </ul>
      </div>
      <div>
        <p>4. Włosy:</p>
        <ul>
          <li>umyte, rozpuszczone, z jednej strony z przodu tak abyśmy mogli zobaczyć ich długość</li>
        </ul>
      </div>
      <div>
        Zdjęcia przykładowe znajdziesz na dole formularza. Wystarczy najechać myszką na ikonkę zdjęcia do załadowania.
        <br/>
        <strong>Nie bierzemy pod uwagę zgłoszeń, które nie spełniają powyższych wymagań.</strong>
      </div>
    </StyledContentWrapper>
  </StyledWrapper>
);

ContactRules.defaultProps = {};

ContactRules.propTypes = {};

export default ContactRules;